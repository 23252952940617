import {ref} from 'vue'
import {projectFirestore} from '../../../../firebase/config'

const suppler_list = new Map()

const get_suppliers_list_with_supply_num = async() => {
    try{
        const docs = await projectFirestore.collection('Shivuk').doc('Shivuk').collection('Suppliers').get()
        docs.forEach(doc => {
            if(doc.data().supply_num){
                suppler_list.set(doc.data().name,doc.data().supply_num)
            }
        })
    }catch(err){
        throw new Error(err)
    }
} 

export{
    get_suppliers_list_with_supply_num,
    suppler_list
}