<template>
  <div v-loading="is_panding" v-if="desktop" class="manage-update-member-container">
    <div class="header">
      <div class="search">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="search" type="text" placeholder="חיפוש" />
        </span>
      </div>
      <div class="btn">
        <Button
          label="חדשים"
          class="p-button-success"
          @click="status_filter = 'חדש'"
        />
      </div>
      <div class="btn">
        <Button
          label="טופלו"
          class="p-button-danger"
          @click="status_filter = 'טופל'"
        />
      </div>
      
<MultiSelect showClear   v-model="selected_options_update" :options="multi_select_options" placeholder="בחירת עדכונים"
    :maxSelectedLabels="3" />
    </div>
    <div class="content">
      <table>
        <tr>
          <th></th>
          <th @click="handleSortTable('נוצר בתאריך', !sortedByclick.order)">
            נוצר בתאריך
          </th>
          <th @click="handleSortTable('סניף', !sortedByclick.order)">סניף</th>
          <th @click="handleSortTable('מזהה לקוח', !sortedByclick.order)">
            מספר מזהה לקוח
          </th>
          <th @click="handleSortTable('שם לקוח', !sortedByclick.order)">
            שם לקוח
          </th>
          <th @click="handleSortTable('תז', !sortedByclick.order)">
            ת"ז/דרכון
          </th>
          <th @click="handleSortTable('יום הולדת', !sortedByclick.order)">
            יום הולדת
          </th>
          <th @click="handleSortTable('מין', !sortedByclick.order)">מין</th>
          <th @click="handleSortTable('טלפון', !sortedByclick.order)">טלפון</th>
          <th @click="handleSortTable('שם העובד המבקש', !sortedByclick.order)">
            שם העובד המבקש
          </th>
          <th @click="handleSortTable('שם הסניף המבקש', !sortedByclick.order)">
            שם סניף המבקש
          </th>
          <th @click="handleSortTable('סטטוס', !sortedByclick.order)">סטטוס</th>
          <th style="text-align: center;" colspan="3">פעולות</th>
        </tr>
        <template v-for="detail in filter_by_options" :key="detail.id">
          <tr>
            <td style="text-align:center;">
              <Checkbox
                name="report"
                :value="detail"
                v-model="checkReports"
              />
            </td>
            <td>{{ new Date(detail.createdAt.seconds * 1000).toLocaleDateString("he") }}</td>
            <td>{{ detail.branche }}</td>
            <td>{{ detail.clientNumber }}</td>
            <td>{{ detail.fullName }}</td>
            <td>{{ detail.idNumber }}</td>
            <td>{{ new Date(detail.birthDay.seconds * 1000).toLocaleDateString("he") }}</td>
            <td>{{ detail.gender }}</td>
            <td>{{ detail.phoneNumber }}</td>
            <td>{{ detail.ownerName }}</td>
            <td>{{ detail.branche }}</td>
            <td v-if="detail.status == 'חדש'" style="color:green;">
              {{ detail.status }}
            </td>
            <td v-if="detail.status == 'טופל'" style="color:red;">
              {{ detail.status }}
            </td>
            <td>
              <!-- <i class="pi pi-info-circle" @click="handleInfo(detail)"></i> -->
              <i  @click="handle_edit(detail)" class="pi pi-pencil" style="color: orange;"></i>
            </td>
            <td>
              <i class="pi pi-trash" style="color: red;  margin: 0 5px;" @click="handleDelete(detail)"></i>
            </td>
            <td>
              <i class="pi pi-reply" @click="handleMashov(detail)"></i>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <Dialog header="מידע נוסף" v-model:visible="showInfo">
      <div class="info">
        <div class="box">
          <h1>כתובת מגורים</h1>
          <p class="display-1">
            <span class="lead">עיר:</span>
            {{
              choosed_request_by_click.city
                ? choosed_request_by_click.city
                : "לא צויין"
            }}
          </p>
          <p class="display-1">
            <span class="lead">רחוב:</span>
            {{
              choosed_request_by_click.street
                ? choosed_request_by_click.street
                : "לא צויין"
            }}
          </p>
          <p class="display-1">
            <span class="lead">מספר בית:</span>
            {{
              choosed_request_by_click.houseNumber > 0
                ? choosed_request_by_click.houseNumber
                : "לא צויין"
            }}
          </p>
          <p class="display-1">
            <span class="lead">דוא"ל של הלקוח</span>
            {{
              choosed_request_by_click.email
                ? choosed_request_by_click.email
                : "לא צויין"
            }}
          </p>
        </div>
        <div class="box">
          <h1>רשימת עדכונים</h1>
          <ul>
            <li
              v-for="update in choosed_request_by_click.multi_select_values"
              :key="update"
            >
              {{ update }}
            </li>
          </ul>
          <h2>פרטים נוספים</h2>
          <p class="display-1">
            {{
              choosed_request_by_click.additionDetails
                ? choosed_request_by_click.additionDetails
                : "לא צויין"
            }}
          </p>
        </div>
      </div>
    </Dialog>
    <Dialog header="משוב" v-model:visible="showMashov">
      <div class="mashov-container">
        <h1>עליך לסמן סטטוס טיפול לבקשה זו</h1>
        <Dropdown
          v-model="choosed_request_by_click.status"
          :options="statusOptions"
          style="width:100%; margin-top:5px;"
          placeholder="סמן סטטוס טיפול"
        />
        <p class="lead" style="margin-top:5px;">הערות</p>
        <Textarea
          rows="5"
          cols="30"
          style="width:100%;"
          v-model="choosed_request_by_click.comment"
        />
        <Button
          label="עדכן"
          class="p-button-secondary"
          style="width:100%;"
          @click="handleUpdate"
        />
      </div>
    </Dialog>


    <Dialog header="טופס עדכון פרטי חבר מועדון"  v-model:visible="show_update_member_dialog">
          <NewUpdateMember
                @close="selected_details_to_edit = null; show_update_member_dialog=false"
                :selected_edit="selected_details_to_edit"
                
          />
    </Dialog>

    <Dialog
      header="חלון מחיקה"
      v-model:visible="showDeleteDialog"
      :style="{ width: '30vw'}"
      position="bottom"
    >
      <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
      <template #footer>
        <Button
          label="לא"
          @click="handleDeleteChecks('לא')"
          class="p-button-success"
        />
        <Button
          label="כן"
          @click="handleDeleteChecks('כן')"
          class="p-button-danger"
        />
      </template>
  </Dialog>
  </div>
  <OnlyForDesktop v-if="!desktop" />
</template>

<script>
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import {alert,alert_confirm} from '../../../../Methods/Msgs'
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import { ref,watch } from "vue";
import { computed, onMounted, onUnmounted } from "@vue/runtime-core";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import NewUpdateMember from "./NewUpdateMember.vue";
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
export default {
  components: { InputText, Dialog, Dropdown, Textarea,OnlyForDesktop,NewUpdateMember,Checkbox,MultiSelect },
  setup() {

    const selected_options_update = ref([])

    const multi_select_options = ref([
          'שם מלא',
          'תעודת זהות',
          'נייד',
          'כתובת',
          'דואר אלקטרוני',
          'קבלת SMS בעברית',
          'קבלת SMS ברוסית',
          'ביטול SMS',
          'תאריך לידה'
    ])
    const showDeleteDialog = ref(false)
    const checkReports = ref([])
    const is_panding = ref(false)

    const show_update_member_dialog = ref(false)

    const selected_details_to_edit = ref(null)
    const desktop = ref(true)
    const statusOptions = ref(["חדש", "טופל"]);
    const details = ref([]);
    const search = ref("");
    const showInfo = ref(false);
    const showMashov = ref(false);
    const choosed_request_by_click = ref({});
    const status_filter = ref("חדש");
    

    const filter_by_options = computed(() => {
      if(!selected_options_update.value.length) return sortedDetails.value
      return sortedDetails.value.filter(detail => selected_options_update.value
      .some(option => detail.multi_select_values.includes(option)))
    })
    
    const sortedDetails = ref(
      computed(() => {
        if (sortedByclick.value.name == "") {
          return filterBySearch.value.sort((a, b) => {
            return new Date(b.createdAt.seconds * 1000) - new Date(a.createdAt.seconds * 1000)
          });
        }
        if (sortedByclick.value.name == "נוצר בתאריך") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return new Date(a.createdAt.seconds * 1000) > new Date(b.createdAt.seconds * 1000)
                ? 1
                : new Date(b.createdAt.seconds * 1000) > new Date(a.createdAt.seconds * 1000)
                ? -1
                : 0;
            }
            return new Date(a.createdAt.seconds * 1000) > new Date(b.createdAt.seconds * 1000)
              ? -1
              : new Date(b.createdAt.seconds * 1000) > new Date(a.createdAt.seconds * 1000)
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "סניף") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.branche > b.branche ? 1 : b.branche > a.branche ? -1 : 0;
            }
            return a.branche > b.branche ? -1 : b.branche > a.branche ? 1 : 0;
          });
        }
        if (sortedByclick.value.name == "שם לקוח") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.fullName > b.fullName
                ? 1
                : b.fullName > a.fullName
                ? -1
                : 0;
            }
            return a.fullName > b.fullName
              ? -1
              : b.fullName > a.fullName
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "מזהה לקוח") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.clientNumber > b.clientNumber
                ? 1
                : b.clientNumber > a.clientNumber
                ? -1
                : 0;
            }
            return a.clientNumber > b.clientNumber
              ? -1
              : b.clientNumber > a.clientNumber
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "תז") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.idNumber > b.idNumber
                ? 1
                : b.idNumber > a.idNumber
                ? -1
                : 0;
            }
            return a.idNumber > b.idNumber
              ? -1
              : b.idNumber > a.idNumber
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "יום הולדת") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return new Date(a.birthDay.seconds * 1000) > new Date(b.birthDay.seconds * 1000)
                ? 1
                : new Date(b.birthDay.seconds * 1000) > new Date(a.birthDay.seconds * 1000)
                ? -1
                : 0;
            }
            return new Date(a.birthDay.seconds * 1000) > new Date(b.birthDay.seconds * 1000)
              ? -1
              : new Date(b.birthDay.seconds * 1000) > new Date(a.birthDay.seconds * 1000)
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "מין") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.gender > b.gender ? 1 : b.gender > a.gender ? -1 : 0;
            }
            return a.gender > b.gender ? -1 : b.gender > a.gender ? 1 : 0;
          });
        }
        if (sortedByclick.value.name == "טלפון") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.phoneNumber > b.phoneNumber
                ? 1
                : b.phoneNumber > a.phoneNumber
                ? -1
                : 0;
            }
            return a.phoneNumber > b.phoneNumber
              ? -1
              : b.phoneNumber > a.phoneNumber
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "דואל") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.email > b.email ? 1 : b.email > a.email ? -1 : 0;
            }
            return a.email > b.email ? -1 : b.email > a.email ? 1 : 0;
          });
        }
        if (sortedByclick.value.name == "שם העובד המבקש") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.ownerName > b.ownerName
                ? 1
                : b.ownerName > a.ownerName
                ? -1
                : 0;
            }
            return a.ownerName > b.ownerName
              ? -1
              : b.ownerName > a.ownerName
              ? 1
              : 0;
          });
        }
        if (sortedByclick.value.name == "שם הסניף המבקש") {
          return filterBySearch.value.sort((a, b) => {
            if (sortedByclick.value.order) {
              return a.branche > b.branche ? 1 : b.branche > a.branche ? -1 : 0;
            }
            return a.branche > b.branche ? -1 : b.branche > a.branche ? 1 : 0;
          });
        }
      })
    );
    
    const sortedByclick = ref({
      name: "",
      order: false
    });
    const handleSortTable = (name, order) => {
      sortedByclick.value.name = name;
      sortedByclick.value.order = order;
    };


    const filterBySearch = ref(
      computed(() => {
        if (search.value == "") {
          if (status_filter.value.length > 0) {
            return details.value.filter(detail => {
              return detail.status == status_filter.value;
            });
          }
          return details.value;
        }
        return details.value.filter(detail => {
          if (
            detail.status == status_filter.value &&
            new Date(detail.createdAt.seconds * 1000)
              .toLocaleDateString("he")
              .includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.branche.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.idNumber.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            new Date(detail.birthDay.seconds * 1000)
              .toLocaleDateString("he")
              .includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.gender.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.phoneNumber.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.email.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.fullName.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.ownerName.includes(search.value)
          )
            return detail;
          if (
            detail.status == status_filter.value &&
            detail.branche.includes(search.value)
          )
            return detail;
        });
      })
    );
    const handleInfo = detail => {
      choosed_request_by_click.value = detail;
      showInfo.value = !showInfo.value;
    };

    const handleMashov = detail => {
      choosed_request_by_click.value = detail;
      showMashov.value = !showMashov.value;
    };

    const handleDelete = (detail) => {
      alert_confirm('האם אתה בטוח שברצונך למחוק בקשה זו?')
      .then(async res => {
        if (res.isConfirmed) {
          await projectFirestore.collection("Applications")
            .doc("7Q1KrNG9Mlm9udT8KBib")
            .collection("Update_club_member")
            .doc(detail.id)
            .delete();
        }
      });
    };
    const handleUpdate = async () => {
      await projectFirestore
        .collection("Applications")
        .doc("7Q1KrNG9Mlm9udT8KBib")
        .collection("Update_club_member")
        .doc(choosed_request_by_click.value.id)
        .set(choosed_request_by_click.value, { merge: true });
        alert("success", "הצלחה","בקשה זו עודכנה בהצלחה")
        .then(() => {
            sendEmailToUser(choosed_request_by_click.value);
            showMashov.value = !showMashov.value;
      });
    };

    const handle_edit = (detail) => {
      console.log(detail);
      selected_details_to_edit.value = JSON.parse(JSON.stringify(detail));
      show_update_member_dialog.value = true
    }

    const handleDeleteChecks = async(option)=>{
      try{
        if(option=='לא'){
           showDeleteDialog.value=false
        }
        if(option=='כן'){
            is_panding.value = true
            for(const detail of checkReports.value){
              await projectFirestore.collection("Applications")
              .doc("7Q1KrNG9Mlm9udT8KBib")
              .collection("Update_club_member")
              .doc(detail.id)
              .delete();
            }
        }
      }catch(err){
        alert_confirm(`שגיאה - ${err.message}`)
        console.error(err);
      }finally{
        checkReports.value=[]
        is_panding.value = false
      }
           
    }


    window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
     });
    onMounted(() => {
      if(window.innerWidth<600){
          desktop.value = false
      }else{
        desktop.value = true
      }
      retrieve_data_from_Db();
    });

    function sendEmailToUser(currentDetails) {
        projectFunctions.httpsCallable('send_email')({
            html: `<div>
                <h3>
                    שלום ${currentDetails.ownerName}
                </h3>
                <h3>
                    בקשתך לעדכון פרטי מועדון מתאריך ${new Date(currentDetails.createdAt.seconds * 1000)
                      .toLocaleDateString("he")}
                </h3>
                <h3>
                    ${currentDetails.multi_select_values.join(", ")}
                </h3>
                <h3>
                    סומנה ע"י הממונה כסטטוס: ${currentDetails.status}
                </h3>
                <h3>
                    הערות: ${
                      currentDetails?.comment?.length > 0
                        ? currentDetails.comment
                        : "אין"
                    }
                </h3>
            </div>`,
            destinations:[`${currentDetails.brancheEmail}`],
            from:"עדכון לקוח מועדון",
            title:`בקשה לעדכון פרטי חבר מועדון ${currentDetails.fullName} נסגרה`
        }) 
    }
    let unsub
    const retrieve_data_from_Db = () => {
         unsub = projectFirestore
        .collection("Applications")
        .doc("7Q1KrNG9Mlm9udT8KBib")
        .collection("Update_club_member")
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              details.value.push(change.doc.data());
            }
            if (change.type === "modified") {
              const index = details.value.findIndex(
                detail => detail.id == change.doc.data().id
              );
              if (index != -1) {
                details.value[index] = change.doc.data();
              }
            }
            if (change.type === "removed") {
              const index = details.value.findIndex(
                detail => detail.id == change.doc.data().id
              );
              if (index != -1) {
                details.value.splice(index, 1);
              }
            }
          });
        });
    };
    
    onUnmounted(()=>{
      window.removeEventListener('resize', ()=>{
          if(window.innerWidth<600){
              desktop.value = false
          }else{
              desktop.value = true
          }
      });
      if(unsub){
          unsub()
      }
    })


    watch(checkReports,()=>{
            if(checkReports.value.length > 0){
                showDeleteDialog.value = true
            }
            else {
                showDeleteDialog.value = false
            }
    })

    return {
      statusOptions,
      status_filter,
      search,
      details,
      showInfo,
      handleDelete,
      handleUpdate,
      handleMashov,
      choosed_request_by_click,
      handleInfo,
      filterBySearch,
      sortedByclick,
      handleSortTable,
      sortedDetails,
      showMashov,
      desktop,
      handle_edit,
      selected_details_to_edit,
      show_update_member_dialog,
      showDeleteDialog,
      handleDeleteChecks,
      checkReports,
      is_panding,
      selected_options_update,
      multi_select_options,
      filter_by_options
    };
  }
};
</script>

<style scoped>
.manage-update-member-container {
  width: 80vw;
  height: 70vh;
}
.header {
  width: 100%;
  height: 5vh;
  display: flex;
}
.header .search {
  margin-left: 5px;
}
.btn {
  margin-left: 5px;
}
.content {
  margin-top: 10px;
  width: 100%;
  height: 70vh;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}
th {
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:hover {
  background: lightskyblue;
}

i {
  cursor: pointer;
}

i:hover {
  color: #f7b00c;
}
.actions {
  display: flex;
  justify-content: space-between;
}
.info {
  width: 50vw;
  height: 40vh;
  display: flex;
}
.box {
  padding: 1rem;
  width: 100%;
  height: 100%;

  line-height: 2.6;
}
.lead {
  font-weight: bold;
}
.display-1 {
  font-size: 1.5rem;
}
ul {
  list-style-type: none;
}
ul li {
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: underline;
}
.mashov-container {
  position: relative;
  width: 40vw;
  height: auto
}

</style>
