<template>
    <div class="display_managments">
        <div class="display_managments-box">
            <div class="display_managments-box-title">
                <h2>רשימת נהלים - {{dep.name}}</h2>
                <div class="exit-btn">
                    <i @click="$emit('close')" class="material-icons">close</i>
                </div>
            </div>
            <div class="display_managments-box-filters">
                <el-input v-model="search" style="margin: 0 5px; width: 240px" placeholder="חיפוש" />
            </div>
            <div class="display_managments-box-content">
                <h2 style="text-align: center; color: red;" v-if="!filter_by_search.length">לא קיימים נהלים!</h2>
                <table v-else>
                    <tr>
                        <th>שם הנוהל</th>
                        <th>פעולות</th>
                    </tr>
                    <template v-for="management in filter_by_search" :key="management.created_at">
                        <tr>
                            <td>{{management.name}}</td>
                            <td style="max-width: 50px;">
                                <i @click="handle_edit_management(management)" style="margin: 5px;" class="material-icons edit-icon">edit</i>
                                <i @click="handle_delete_management(management)" style="margin: 5px;" class="material-icons delete-icon">delete</i>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>

        <CreateManagement 
            v-if="selected_managment_to_edit"
            :dep="dep"
            :management_for_edit="selected_managment_to_edit"
            @close="selected_managment_to_edit = null"
        />
    </div>
</template>

<script>
import { ref, defineAsyncComponent, computed } from 'vue'
import {alert_confirm,slide_pop_error} from '../../../../../Methods/Msgs'
import { update_managments_in_dep_db, delete_images_from_storage } from '../store'

export default {
    emits:['close'],
    props:['dep'],
    components:{
        CreateManagement: defineAsyncComponent(() => import('../components/CreateManagement.vue'))
    },
    setup (props) {
        
        const search = ref('')

        const selected_managment_to_edit = ref(null)

        const filter_by_search = computed(() => {
            if(!search.value) return props.dep.managements
            return props.dep.managements.filter(mgmt =>  mgmt.name.includes(search.value) || mgmt.description?.includes(search.value))
        })
        const handle_edit_management = (management) => {
            selected_managment_to_edit.value = JSON.parse(JSON.stringify(management));
        }

        const handle_delete_management = async (management) => {
            try {
                alert_confirm(`למחוק את הנוהל ${management.name}?`)
                    .then(async (res) => {
                        if (res.isConfirmed) {
                            const index = props.dep.managements.findIndex(m => m.created_at === management.created_at);
                            if (index !== -1) {
                                const images = props.dep.managements[index].images;

                                try {
                                    await delete_images_from_storage(images);
                                } catch (error) {
                                    console.error("Error deleting images:", error);
                                }

                                // Remove the management entry from the array
                                props.dep.managements.splice(index, 1);

                                // Proceed to update the database, regardless of delete_images_from_storage result
                                await update_managments_in_dep_db(props.dep);
                            }
                        }
                    });
            } catch (err) {
                console.error(err);
                slide_pop_error(err.message);
            }
        };


      

        return {
            handle_edit_management,
            selected_managment_to_edit,
            handle_delete_management,
            search,
            filter_by_search
        }
    }
}
</script>

<style scoped>
    .display_managments{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 5px;
    }
    .display_managments-box{
        width: 100%;
        max-width: 500px;
        height: 60%;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        color: #333;
    }
    .display_managments-box-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .display_managments-box-filters{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .display_managments-box-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--danger);
        border-radius: 50%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--main);
        color: white;
        position: sticky;
        top: 0;
        font-size: 18px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .edit-icon,.delete-icon{
        user-select: none;
        cursor: pointer;
    }
    .edit-icon{
        color: var(--warning);
    }
    .delete-icon{
        color: var(--danger);
    }
</style>