<template>
  <div class="branche">
      <div class="branche-name">
        <h3>{{branche.name}}</h3>
      </div>
    
      <div class="actions">
          <img @click="handle_delete(branche.name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ftrash-bin_375x375.png?alt=media&token=343ab5d5-380b-4c00-9c6d-ca5152b4673b" alt="">
          <img @click="update_branche(branche.name)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fpencil%20(1)_375x375.png?alt=media&token=78a64ec1-c7b4-49d0-ba40-963c4db41bed" alt="">
      </div>    
  </div>
</template>

<script>
import {alert_confirm} from '../../../../Methods/Msgs'
import {projectFirestore} from '../../../../firebase/config'
import Swal from 'sweetalert2'
export default {
    props:['branche'],
    emits:['delete','modify'],
    setup(props,{emit}){
        const update_branche = async (uid) => {
            Swal.fire({
                icon: 'question',
                title: `עדכון פרטי סניף - ${uid}`,
                html: `
                    <input id="branchName" value="${props.branche.name.replace(/"/g, '&quot;')}" type="text" class="swal2-input" placeholder="שם סניף">
                    <input id="branchNum" value=${props.branche.branche_num} type="text" class="swal2-input" placeholder="מס סניף">
                `,
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                preConfirm: () => {
                    const branchName = document.getElementById('branchName').value.trim();
                    const branchNum = document.getElementById('branchNum').value.trim();

                    if (!branchName || !branchNum) {
                        Swal.showValidationMessage('כל השדות חייבים להיות מלאים');
                        return null;
                    }
                    return { branchName, branchNum };
                }
            }).then(async (res) => {
                if (res.isConfirmed && res.value) {
                    const { branchName, branchNum } = res.value;
                    await handle_modify_branche(uid, { branchName, branchNum });
                }
            });
        };

        const handle_delete = async(branche_name)=>{
            alert_confirm(`האם למחוק את סניף ${branche_name}`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await projectFirestore.collection('Shivuk')
                    .doc('Shivuk').collection('Branches').doc(branche_name)
                    .delete()
                    emit('delete',branche_name)
                }
            })

        }
        const handle_modify_branche = async (old_branche_name, { branchName, branchNum }) => {
            console.log(old_branche_name);
            // Delete the old branch document
            await projectFirestore.collection('Shivuk')
                .doc('Shivuk')
                .collection('Branches')
                .doc(old_branche_name)
                .delete();

            // Create a new branch document with the updated information
            await projectFirestore.collection('Shivuk')
                .doc('Shivuk')
                .collection('Branches')
                .doc(branchName) // Using branchNum as the new document ID
                .set({
                    name: branchName,
                    branche_num: branchNum // Include the branch number in the document
                });

            // Emit an event with the updated branch details
            emit('modify', {
                old_branche_name,
                new_branche_name: branchName, // Use the new branch number as identifier
                branchNum
            });
        };

        return{handle_delete,handle_modify_branche,update_branche}
    }
}
</script>

<style scoped>
    .branche{
        background: #fff;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15); 
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15);
        border-radius: 10px;
        display: flex;
    }
    .branche-name{
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .actions{
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    img{
        cursor: pointer;
        width: 32px;
        height: 32px;
    }
</style>