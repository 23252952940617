<template>
    <div class="departments">
        <div class="departments-title">
            <h2>ניהול מחלקות</h2>
        </div>
        <div class="departments-options">
            <el-button @click="show_create_dep = true" type="primary">הוספת מחלקה</el-button>
            <el-input v-model="search" style="margin: 0 5px; width: 240px" placeholder="חיפוש" />
        </div>
        <div v-loading="is_panding" class="departments-content">
            <h2 style="text-align: center; color: red;" v-if="!filter_by_search.length">לא קיימות רשומות!</h2>
            <table v-if="filter_by_search.length">
                <tr>
                    <th>שם</th>
                    <th>מס נהלים</th>
                    <th colspan="2">פעולות</th>
                </tr>
                <template v-for="dep in filter_by_search" :key="dep.uid">
                    <tr @click="print(dep)">
                        <td  style="max-width: 50px;">{{dep.name}}</td>
                        <td  style="max-width: 50px;">{{dep.managements.length}}</td>
                        <td style="max-width: 50px;">
                            <el-button @click="selected_dep_to_display_managments = dep" style="margin: 5px;" size="small" type="primary">צפייה</el-button>
                            <el-button @click="selected_dep_to_create_managment = dep" style="margin: 0"  size="small" type="success">הוספה</el-button>
                        </td>
                        <td style="max-width: 50px;">
                            <i @click="handle_delete_dep(dep)" style="margin: 5px;" class="material-icons delete-icon">delete</i>
                            <i @click="handle_edit_dep(dep)" style="margin: 5px;" class="material-icons edit-icon">edit</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>

        <CreateDepartment 
            v-if="show_create_dep"
            :selected_dep_to_edit="selected_dep_to_edit"
            @close="show_create_dep = false; selected_dep_to_edit = null"
        />

        <CreateManagement 
            v-if="selected_dep_to_create_managment"
            :dep="selected_dep_to_create_managment"
            @close="selected_dep_to_create_managment = null"
        />

        <DisplayManagments 
            v-if="selected_dep_to_display_managments"
            :dep="selected_dep_to_display_managments"
            @close="selected_dep_to_display_managments = null"
        />
        
    </div>
</template>

<script>
import { ref,defineAsyncComponent,computed } from 'vue'
import {delete_images_from_storage, departments,get_departments_from_db,delete_department_from_db} from '../store'
import {slide_pop_error,alert_confirm} from '../../../../../Methods/Msgs'

export default {
    components:{
        CreateDepartment: defineAsyncComponent(() => import('../components/CreateDepartment.vue')),
        CreateManagement: defineAsyncComponent(() => import('../components/CreateManagement.vue')),
        DisplayManagments: defineAsyncComponent(() => import('../components/DisplayManagments.vue'))
    },
    setup () {
        
        const search = ref('')

        const selected_dep_to_create_managment = ref(null)
        const selected_dep_to_display_managments = ref(null)
        const selected_dep_to_edit = ref(null)

        const show_create_dep = ref(false)

        const is_panding = ref(false)

        const filter_by_search = computed(() => {
            if (!search.value) return departments.value;

            return departments.value.filter(dep =>
                dep.name.includes(search.value) ||
                dep.managements?.some(mgmt =>
                    mgmt.name.includes(search.value) || mgmt.description?.includes(search.value)
                )
            );
        });


        const print = (dep) => {
            console.log(dep);
        }

        const handle_delete_dep = async (dep) => {
            try {
                const res = await alert_confirm(`למחוק את הנוהל ${dep.name}?`);
                
                if (res.isConfirmed) {
                    is_panding.value = true
                    const managements = dep.managements;

                    // Use Promise.all with map to delete images in parallel
                    const deletionPromises = managements.map((m) => 
                        delete_images_from_storage(m.images).catch(err => {
                            console.error(`Error deleting images for management ${m.name}:`, err);
                            return null; // Continue execution even if one deletion fails
                        })
                    );

                    // Wait for all image deletions to complete
                    await Promise.all(deletionPromises);

                    // Proceed with deleting the department from the database
                    await delete_department_from_db(dep);
                }
            } catch (err) {
                slide_pop_error(err.message);
                console.error(err);
            }finally{
                is_panding.value = false
            }
        };


        const handle_edit_dep = async (dep) => {
            selected_dep_to_edit.value = JSON.parse(JSON.stringify(dep));
            show_create_dep.value = true
        }


        const init = async() => {
            try {
                is_panding.value = true
                await get_departments_from_db()
                console.log(departments.value);
            } catch (err) {
               console.error(err.message); 
            } finally{
                is_panding.value = false
            }
        }

        init()

        return {
            is_panding,
            departments,
            show_create_dep,
            search,
            filter_by_search,
            selected_dep_to_create_managment,
            selected_dep_to_display_managments,
            handle_delete_dep,
            selected_dep_to_edit,
            handle_edit_dep,
            print,
        }
    }
}
</script>

<style scoped>
    .departments{
        width: 100%;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
    }
    .departments-options,.departments-title{
        width: 100%;
        height: 50px;
        padding: 0 5px;
        display: flex;
        align-items: center;
       
    }
    .departments-title{
        justify-content: center;
        background: var(--secondary);
        border-radius: 10px;
    }
    .departments-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }


    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .edit-icon,.delete-icon{
        user-select: none;
        cursor: pointer;
    }
    .edit-icon{
        color: var(--warning);
    }
    .delete-icon{
        color: var(--danger);
    }

    .mobile-table{
          margin-bottom: 5px;
    }
</style>